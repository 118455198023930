import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Move often, move well!', // e.g: 'Name | Developer'
  lang: 'EN', // e.g: en, es, fr, jp
  description: 'All PT sessions are done outdoors in various locations throughout Copenhagen.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Start your fitness journey now! ',
  subtitle: 'Outdoor PT Sessions',
  name: 'with Silviu',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile1.jpg',
  paragraphOne:
    'With 20+ years experience in strength and conditioning, testing different tools, equipment and training concepts, Silviu is highly experienced with functional movements and metabolic conditioning methods. Fascinated by how the human body is designed and built to move, loves to experiment with all sort of movements.',
  paragraphTwo:
    'As your Coach, my goal is to guide people towards achieving their very best. And, most importantly, in a safely and secure manner! Always chasing excellence in everything I do.',
  paragraphThree:
    'All you need is building a solid foundation and apply consistent work. You bring on your body and desire to move, I bring my knowledge and build a plan that is tailored to your needs.',
  paragraphFour:
    'No matter how complex or complicated a movement might look/feel, everything is broken in simple and precise parts, movements become easy and friendly to learn. Results are guaranteed. :-)',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'CFC Copenhagen Academy Coach',
    info: 'Coaching groups of athletes on a daily business in the biggest Cross Fitness gym chain of North Europe.',
    info2:
      'Experienced in Strength & Conditioning, Weightlifting, Gymnastics, Plyometrics, Running & Cycling, Silviu will guide you learning fast all advanced functional movements with barbells, dumbbells, kettlebells, medballs, slamballs, pull-up bar, gymnastic rings, battle and climbing ropes and mono-structural machines. Focus is on movement quality, as quality builds quantity. ;-)',
  },
  {
    id: nanoid(),
    img: 'project2.jpg',
    title: 'World Kettlebell Sport Federation by Oleh Ilika',
    info: 'Kettlebell Coach L1',
    info2:
      'High proficiency for specific Kettlebell movements: Swing, Snatch, Clean, Press, Jerk, Windmill, Turkish Get-Up, etc. Focus will be on developing necessary skills to master any of these movements and control a kettlebell with ease.',
  },
  {
    id: nanoid(),
    img: 'project5.jpg',
    title: 'D.I.M.I Fitness by Dumitru Butilca',
    info: 'TRX Suspension Trainer Coach L1',
    info2:
      'Using your body as a machine, with the Suspension Trainer we will focus on Core mobility and stability. It´s a wonderful tool that will open up your mind to a new level and discover your body in a different way.',
  },
  {
    id: nanoid(),
    img: 'project6.jpg',
    title: 'Move-On Fitness Education with Iulian Panait',
    info: 'Bulgarian Bag Coach',
    info2:
      'An extremely powerful Core & Functional developing tool created by the Bulgarian olympic athlete Ivan Ivanov. Using the Bulgarian bag we work on improving explosive actions and dynamic movements involved in pushing, twisting, swinging, pulling, bending, rotating, squatting, lunging, and throwing.',
  },
  {
    id: nanoid(),
    img: 'project3.jpg',
    title: 'FTS Italy by Guido Bruscia',
    info: 'Functional Training Advanced Certified',
    info2:
      'Highly experienced with most functional movements out there, focused on building a solid movements foundation. Building a solid ground is the focus in the first part of the journey, this a stage that cannot and will not be skipped, it is a must for future development.',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/silviucalin/',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/Monsieur__Calin',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/silviu.calin/',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/silviu-calin/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
